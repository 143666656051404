
.main-btn {
	background-color: $darkColor;
	border: 1px solid $darkColor;
	font-size: 16px;
	letter-spacing: 2px;
	line-height: 27px;
	color: $whiteColor;
	border-radius: 10px;
	font-weight: 400;
	cursor: pointer;
	padding: 0.8rem 2rem;
	font-weight: bold;
}

.main-btn:hover,
.main-btn:active {
	background-color: $darkHoverColor;
	border: 1px solid $darkHoverColor;
}

@media (max-width: 992px) {
	.main-btn {
		padding: 0.5rem 0.8rem;
	}
}

.yellow-btn {
	background-color: $yellowColor;
	border: 1px solid $yellowColor;
	color: $blackColor;
}

.yellow-btn:hover,
.yellow-btn:active {
	background-color: $yellowHoverColor;
	border: 1px solid $yellowHoverColor;
}