.mt-6-rem {
	margin-top: 6rem;
}
.mb-6-rem {
	margin-bottom: 6rem;
}

.mt-4-rem {
	margin-top: 4rem;
}

@media (max-width: 992px) {
	.mt-6-rem {
		margin-top: 2rem;
	}	
	.mb-6-rem {
		margin-bottom: 2rem;
	}
	.mt-4-rems {
		margin-top: 1.25rem;
	}
}

.mt-2-rem {
	margin-top: 2rem;
}

.gap-10 {
	gap: 10px;
}

.gap-20 {
	gap: 20px;
}

.w-fit {
	width: fit-content;
}