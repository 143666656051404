.bg-yellow {
	background-color: $yellowColor;
}

.bg-dark {
	background-color: $darkColor !important;
}

.bg-p-center {
	background-position: center;
}

.black-bg {
	background-color: #000;
}