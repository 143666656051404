
.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.overlay-black {
	background-color: rgba(0, 0, 0, 0.5);
}

.overlay-top-shadow {
	background: linear-gradient(180deg, #000000 -40.21%, rgba(255, 255, 255, 0) 100.05%);
}

.overlay-left-shadow {
	background: linear-gradient(90deg, #000000 20.52%, rgba(255, 255, 255, 0) 111.39%);
}

.overlay-bottom-shadow {
	background: linear-gradient(2deg, #000000 10.99%, rgba(255, 255, 255, 0) 81.73%);
}
