@import "./includes/colors";
@import "./includes/backgrounds";
@import "./includes/buttons";
@import "./includes/typography";
@import "./includes/animation";
@import "./includes/overlay";
@import "./includes/border";
@import "./includes/spacing";

body {
	margin: 0;
	padding: 0;
	background-color: #f6f6f6;
	// height: 100000px;
}

* {
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
}

.mt-6 {
	margin-top: 6rem;
}

@media (max-width: 992px) {
	.mt-6 {
		margin-top: 3rem;
	}
}

.grayscale {
	filter: grayscale(100%);
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
}

.grayscale:hover {
	filter: grayscale(0%);
	-webkit-filter: grayscale(0%);
	-moz-filter: grayscale(0%);
	-ms-filter: grayscale(0%);
	-o-filter: grayscale(0%);
}

.icon-32 {
	width: 32px;
	height: 32px;
	fill: $darkColor;
}

.icon-16 {
	width: 16px;
	height: 16px;
}

.icon-24 {
	width: 24px;
	height: 24px;
}

.icon-yellow {
	fill: $yellowColor;
}

.icon-white {
	fill: $whiteColor;
}

.icon-stroke-yellow {
	stroke: $yellowColor;
}

@media (max-width: 992px) {
	.flex-column-reverse-md {
		flex-direction: column-reverse !important;
	}
	.icon-52 {
		// width: auto;
		min-width: 100px !important;
	}
}

.icon-52 {
	min-width: 60px !important;
	// height: 100px !important;
	padding: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
}

.yellow-line {
    height: 2px;
    background: $yellowColor;
}

.ver-line {
	width: 16px;
	background: $yellowColor;
}

.clickable-icon {
	cursor: pointer;
}

.clickable-icon:hover {
	background-color: $blackColor;
	color: $whiteColor;
}

.swiper-pagination-bullet-active {
	background-color: $yellowColor !important;
}