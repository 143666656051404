
.b-r-10 {
	border-radius: 10px;
}

.b-btm-7-yellow {
	border-bottom: 7px solid $yellowColor;
}


.b-top-7-yellow {
	border-top: 7px solid $yellowColor;
}

.b-5-yellow {
	border: 5px solid $yellowColor;
}


.b-1-dark {
	border: 1px solid $darkColor;
}