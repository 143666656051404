.text-40-reguler {
	font-size: 43px;
	line-height: 56px;
}

.text-40-bold {
	@extend .text-40-reguler;
	font-weight: 700;
}

@media (max-width: 992px) {
	.text-40-reguler {
		font-size: 36px;
		line-height: 40px;
	}
}

.text-32-bold {
	font-size: 32px;
	line-height: 36px;
	font-weight: 700;
}

.text-26-bold {
	font-size: 26px;
	line-height: 36px;
	font-weight: 700;
}


.text-23-bold {
	font-size: 23px;
	line-height: 36px;
	font-weight: 700;
}

.text-20-regular {
	font-size: 20px;
	letter-spacing: 0px;
	line-height: 27px;
}

.text-20-bold {
	@extend .text-20-regular;
	font-weight: 600;
}

@media (max-width: 992px) {
	.text-20-regular {
		font-size: 16px;
		line-height: 24px;
	}
}

.text-19-regular {
	font-size: 19px;
	letter-spacing: 0px;
	line-height: 27px;
}

.text-19-bold {
	@extend .text-19-regular;
	font-weight: 600;
}

.text-18-regular {
	font-size: 18px;
	letter-spacing: 0px;
	line-height: 30px;
}

.text-16-regular {
	font-size: 16px;
	letter-spacing: 0px;
	line-height: 27px;
}

.text-16-bold {
	@extend .text-16-regular;
	font-weight: 600;
}

.txt-cap {
	text-transform: capitalize;
}

.header {
	@extend .yellow-clr;
	@extend .text-20-bold;
	@extend .txt-cap;
}

.sub-header {
	@extend .text-40-bold;
	@extend .txt-cap;
	margin-top: 0rem;
	margin-bottom: 2rem;
}

@media (max-width: 992px) {
	.sub-header {
		// margin-top: .5rem;
		margin-bottom: 1rem;
	}
}

.para {
	@extend .text-19-regular;
	@extend .beige-clr;
}


.text-underline {
	text-decoration: underline;
}